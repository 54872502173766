import NovoApp from "../assets/img/novoApp.png";
// import Newletterbg from "../assets/img/newsletterbg.png"

const Newsletter = () => {
  return (
    <div className="container text-secondary mx-auto bg-primary my-24 md:pe-0 rounded-3xl relative">
      <div className="flex ">
        <div className="text-white p-0 md:p-8 mt-8">
          {/* <img src={Newletterbg} alt="Novo app screenshot" className=""/> */}
          <h1 className="text-2xl md:text-4xl font-bold">
            Signup for newsletter
          </h1>
          <p className="mt-4 text-base">
            Stay updated with the latest news, exclusive offers, and insightful
            articles straight to your inbox.
          </p>
          {/* <form className="mt-16 mb-8 md:mb-0 flex">
            <input
              type="email"
              placeholder="Email"
              className="rounded-md ps-4 h-12 md:w-80 text-secondary"
            />

            <button className="bg-secondary py-3 px-6 ms-1 rounded-md text-white hover:opacity-90">
              Signup
            </button>
          </form> */}
          <div className="my-8 md:mt-16  hover:scale-[1.02] duration-300">
              <a
                href="/waitlist"
                className="bg-secondary px-2 py-2 md:h-12 md:py-3  md:px-8 rounded-md text-white"
              >
                Join Waitlist
              </a>
            </div>
        </div>
        <div className="hidden lg:block">
          <img src={NovoApp} alt="Novo app screenshot " />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
