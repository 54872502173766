
export default function FaqComponent() {
    return (
        <div className="flex items-center mt-10 md:mt-10">
            <div className="">
                <div className="flex flex-col  w-full">

                    <div className="space-y-5">
                        <details className="w-full text-left rounded-2xl  bg-white">
                            <summary className="py-6  md:w-[996px] text-2xl font-medium">
                            What is NovoPay?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Novo will be available  in 2024. You can join the waitlist to be notified when we launch.                            </p>
                        </details>
                        <details className="w-full text-left rounded-2xl  bg-white">
                            <summary className="py-6  md:w-[996px] text-2xl font-medium">
                            What is Novo Requests
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
                            Answer                        </p>
                        </details>
                        <details className="w-full text-left rounded-2xl  bg-white">
                            <summary className="py-6  md:w-[996px] text-2xl font-medium">
                            Where can I use Novo virtual cards?
                            </summary>
                            <p className="px-4 py-6 pt-0 ml-4">
Answer                 </p>
                        </details>


                    </div>
                </div>
            </div>
        </div>
    );
}
