import { useState, useEffect } from "react";
// import Logo from "../assets/img/Logo.svg";
import hamburger from "../assets/img/hamburger.svg";
import close from "../assets/img/X.svg";

const Navbar = ({ initialTextColor = "text-white" }) => {
  const navBar = [
    { title: "Home", url: "/" },
    { title: "About", url: "/about" },
    { title: "Contact Us", url: "/contact" },
    { title: "FAQ", url: "faq" },
  ];
  const [nav, setNav] = useState(false);
  const [bgColor, setBgColor] = useState("bg-transparent");
  const [textColor, setTextColor] = useState(initialTextColor);

  const handleClick = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        setBgColor("bg-white");
        setTextColor("text-secondary");
      } else {
        setBgColor("bg-transparent");
        setTextColor(initialTextColor);
      }
    };

    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, [initialTextColor]);

  return (
    <div>
      <div
        className={`hidden md:block fixed z-20 drop-shadow-md w-full py-2 ${bgColor} duration-300`}
      >
        <nav
          className={`hidden md:flex justify-between items-center py-3 container mx-auto ${textColor}`}
        >
          <div>
            <a href="/" className="font-bold text-3xl">
              {/* <img src={Logo} alt="Novo Logo" /> */}
              novo<span className="text-primary">Pay</span>
            </a>
          </div>
          <div className="flex items-center">
            <ul className="flex lg:ms-12">
              {navBar.map(({ title, url }, idx) => (
                <li key={idx}>
                  <a href={url} className="mx-3 hover:opacity-70">
                    {title}
                  </a>
                </li>
              ))}
            </ul>
            <div className="relative ms-3 me-12 group">
              <button className={` ${textColor} hover:opacity-70 duration-300 relative z-10`}>
                Features
              </button>

              <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg text-secondary rounded-md py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20">
                <a
                  href="/transfers"
                  className="block px-4 py-2 text-black hover:bg-gray-200"
                >
                  Transfer
                </a>
                <a
                  href="/cards"
                  className="block px-4 py-2 text-black hover:bg-gray-200"
                >
                  Cards
                </a>
              </div>
            </div>
            <a
              href="/waitlist"
              className="bg-primary py-2 px-2 lg:px-6 rounded-md text-white hover:opacity-70 duration-300"
            >
              Join Waitlist
            </a>
          </div>
        </nav>
      </div>

      <div className="container mx-auto vh-10 flex md:hidden items-center justify-between px-3 ">
        <div className="p-3 z-30">
          <a href="/" className="font-bold text-3xl">
            {/* <img src={Logo} alt="Novo Logo" /> */}
            novo<span className="text-primary">Pay</span>
          </a>
        </div>
        <div onClick={handleClick} className="cursor-pointer p-3 z-30">
          {!nav ? (
            <img src={hamburger} alt="logo" className="" />
          ) : (
            <img src={close} alt="" className="" />
          )}
        </div>
      </div>
      <div
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 bg-white w-full h-screen flex flex-col justify-center items-center z-20"
        }
      >
        <ul className="text-3xl font-bold text-center">
          {navBar.map(({ title, url }, idx) => (
            <li key={idx} className="py-5">
              <a href={url} onClick={handleClick}>
                {title}
              </a>
            </li>
          ))}
        </ul>
        <a
          href="/waitlist"
          className="bg-primary py-2 px-8 mt-10 rounded-full text-white hover:opacity-70 duration-300"
        >
          Join Waitlist
        </a>
      </div>
    </div>
  );
};

export default Navbar;
