// import NovoLogo from "../assets/img/NovoLogo.svg";

const Footer = () => {
  return (
    <footer className="bg-[#000000] text-white">
      <div className="container mx-auto hidden md:block lg:flex items-start justify-between  py-20 ">
        <div>
          {/* <img src={NovoLogo} alt="Novo Logo" /> */}
          <a href="/" className="font-bold text-3xl">
            novo<span className="text-primary">Pay</span>
          </a>
        </div>
        <div className="flex mt-12 lg:mt-0">
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/about" className="opacity-90 mt-8 mb-4">
              About Us
            </a>
            <a href="/" className="opacity-90 mb-4">
              Careers
            </a>
            <a href="/faq" className="opacity-90 mb-4">
              FAQs
            </a>
            <a href="/contact" className="opacity-90">
              Contact Us
            </a>
          </div>
          <div className="mx-20 flex flex-col">
            <h5 className="font-bold">Socials</h5>
            <a href="/" className="opacity-90 mt-8 mb-4">
              Linkedin
            </a>
            <a href="/" className="opacity-90 mb-4">
              Twitter
            </a>
            <a href="/" className="opacity-90 mb-4">
              Instagram
            </a>
          </div>
          <div className="mx-20 flex flex-col">
            {/* <h5 className="font-bold">Socials</h5> */}
            <a href="/" className="opacity-90 mb-4">
              hello@novopay.com
            </a>
            <a href="/" className="opacity-90 mb-4">
              +234 654 654 3532
            </a>
            <a href="/" className="opacity-90 mb-4">
              Lagos, Nigeria
            </a>
          </div>
        </div>
      </div>


      <div className="container mx-auto md:hidden flex flex-col items-start justify-between  py-20 ">
        <div className="mb-5">
          <a href="/" className="font-bold text-3xl">
            {/* <img src={Logo} alt="Paytent Logo" /> */}
            novo<span className="text-primary">Pay</span>
          </a>
        </div>
        <div className="flex ">
          <div className="m-5 flex flex-col">
            <h5 className="font-bold">Company</h5>
            <a href="/about" className="opacity-90 mt-8 mb-2">
              About
            </a>
            <a href="/about" className="opacity-90 mb-2">
              Our team
            </a>
            <a href="/contact" className="opacity-90 mb-2">
              Contact us
            </a>
            <a href="/faq" className="opacity-90">
              FAQs
            </a>
          </div>
          <div className="m-5 flex flex-col">
            <h5 className="font-bold">Socials</h5>
            <a href="/" className="opacity-90 mt-8 mb-2">
              Linkedin
            </a>
            <a href="/" className="opacity-90 mb-2">
              Twitter
            </a>
            <a href="/" className="opacity-90 mb-2">
              Instagram
            </a>
          </div>
          
        </div>
        <div className="m-5 flex flex-col">
            {/* <h5 className="font-bold">Company</h5> */}
            <a href="/" className="opacity-90 mt-8 mb-2">
            hello@novopay.com
            </a>
            <a href="/" className="opacity-90 mb-2">
            +234 654 654 3532
            </a>
            <a href="/" className="opacity-90 mb-2">
              Lagos, Nigeria
            </a>
          </div>
      </div>
      <div>
        <hr />
        <p className="py-10 text-center"> © 2024 NovoPay. </p>
      </div>
    </footer>
  );
};

export default Footer;
