import React from "react";
import Footer from "../../components/Footer";
import Newsletter from "../../components/Newsletter";
import Navbar from "../../components/Navbar";

const Contact = () => {
  return (
    <div>
      <Navbar initialTextColor="text-secondary"/>
      <div className="bg-[#F7F7F7] h-[80px]"></div>
      <div className="bg-[#F7F7F7] h-40">
        <h1 className="text-4xl text-center font-bold pt-12">Contact Us</h1>
      </div>

      <div className="container mx-auto bg-[#F7F7F7] rounded-3xl p-10 mt-20">
        <p className="text-center">
          Get in touch<br /> We are excited to hear from you
        </p>

        <form className="gap-4 mt-16 flex flex-col items-center w-full md:w-1/2 mx-auto">
          <input
            type="text"
            placeholder="Name"
            className="border rounded-md ps-3 w-full h-12"
          />
          <input
            type="email"
            placeholder="Email"
            className="border rounded-md ps-3 w-full h-12"
          />
          <textarea
            id="details"
            placeholder="Message"
            className="p-4 rounded-md w-full border h-[220px]"
            required
          ></textarea>
          <button className="bg-[#000000] text-white py-4 w-full rounded-full">
            SUBMIT
          </button>
        </form>
      </div>
      <div className="p-4">
        <Newsletter />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
