import Navbar from "../../components/Navbar";
import GuyPhone from "../../assets/img/youngguy.png";
import AppScreenshot from "../../assets/img/appScreenshot.png";
import TwoPeople from "../../assets/img/twoPeople.png";
import ScanToPay from "../../assets/img/scanToPay.png";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import FaqComponent from "../../components/Faq";

export const Home = () => {
  return (
    <div>
      <Navbar initialTextColor="text-white" />
      <div className="bg-img w-screen h-[280px] md:h-[520px] lg:h-screen bg-contain bg-no-repeat lg:bg-cover flex flex-col justify-end">
        <div className="text-center p-2 md:p-14 text-white">
          <h1 className="mx-auto font-bold text-2xl md:text-4xl lg:text-6xl lg:w-[900px] mt-">
            Enjoy fast and easy money transfer, bills payment.
          </h1>
          <p className="my-2 md:mt-8 text-sm md:text-base">
            Join our waitlist to be the first to try Novo
          </p>
          <div>
            {/* <form className="md:mt-6">
              <input
                type="email"
                placeholder="Email"
                className="rounded-md ps-4 w-54 h-9 md:h-12 md:w-80 text-secondary"
              />

              <button className="bg-primary px-2 h-9 md:h-12 md:py-3  md:px-6 ms-1 rounded-md text-white hover:scale-[1.02] duration-300">
                Join Waitlist
              </button>
            </form> */}
            <div className="mt-4 mb-12 md:mb-2 md:mt-6  hover:scale-[1.02] duration-300">
              <a
                href="/waitlist"
                className="bg-primary px-2 py-2 md:h-12 md:py-3  md:px-8 rounded-md text-white"
              >
                Join Waitlist
              </a>
            </div>
          </div>
        </div>
      </div>

      <section className="md:container p-4 py-12 text-secondary mx-auto">
        {/* Replace icons svg*/}
        <h1 className="capitalize text-2xl md:text-4xl font-semibold">
          do more with novo
        </h1>
        <div className="mt-8 md:mt-20 md:flex overflow-x-scroll gap-4">
          <div className="flex justify-between gap-2 md:gap-4 mt-2">
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 md:gap-4 mt-2">
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#F5F5F5]">
        <div className="md:container px-4 pt-2 md:pt-16 text-secondary mx-auto md:flex">
          <div className="w-4/5 my-8">
            <h1 className="text-2xl md:text-3xl lg:text-5xl leading-snug font-bold">
              Effortless Transfers,
              <br /> Happy Moments.
            </h1>
            <p className="my-8 lg:text-xl md:w-2/3 font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim.
            </p>
            <a href="/transfers" className="underline text-primary text-xl">
              Learn More
            </a>
          </div>
          <div className="md:w-[500px] lg:w-[700px]">
            <img src={GuyPhone} alt="Guy showing Novo App on Phone" />
          </div>
        </div>
      </section>

      <section className="container text-secondary mx-auto bg-[#F9F3FF] my-24 rounded-3xl">
        <div className="md:container flex flex-col-reverse md:flex-row justify-between pt-16">
          <div className="w-full">
            <img src={AppScreenshot} alt="Novo app screenshot" />
          </div>

          <div className="sm:ms-8 pt-6">
            <h1 className="capitalize text-2xl md:text-3xl lg:text-5xl leading-normal font-bold">
              Enjoy fast and easy money transfer, bills payment.
            </h1>
            <p className="my-8 lg:text-xl  font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupidatat non proident, sunt
              in culpa qui officia
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#F5F5F5]">
        <div className="px-4 md:container pt-16 text-secondary mx-auto flex flex-col-reverse md:flex-row">
          <div className="md:w-4/5 mt-8">
            <h1 className="capitalize text-2xl md:text-3xl lg:text-5xl leading-snug font-bold">
              Effortless Transfers,
              <br /> Happy Moments.
            </h1>
            <p className="my-8 lg:text-xl font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui
              <br /> officia deserunt mollit anim.
            </p>
            <a href="/cards" className="underline text-primary text-xl">
              {" "}
              Learn More
            </a>
          </div>
          <div className="md:w-[700px] lg:w-full">
            <img src={TwoPeople} alt="Guy showing Novo App on Phone" />
          </div>
        </div>
      </section>

      <section className="card-img w-full h-[246px] md:h-[520px] lg:h-screen bg-contain lg:bg-cover bg-no-repeat flex flex-col justify-end mt-8 md:p-14">
        <h1 className="container font-bold capitalize text-2xl md:text-4xl lg:text-6xl md:w-[600px] lg:w-[900px] md:mb-16 leading-tight text-white">
          run international transactions with <br /> Novo.
        </h1>
      </section>

      <section className="bg-[#F5F5F5] md:mt-14">
        <div className="pt-12 text-secondary mx-auto md:flex">
          <div>
            <img src={ScanToPay} alt="Lady showing Novo App on Phone" />
          </div>
          <div className="md:w-4/5 mt-8 p-4 md:px-8 lg:px-20">
            <h1 className="text-2xl md:text-3xl lg:text-5xl leading-snug font-bold">
              Scan to Pay, Simplify Your Day
            </h1>
            <p className="my-8 lg:text-xl font-normal">
              Experience the convenience of seamless transactions with our Scan
              to Pay feature. Effortlessly make payments by simply scanning a QR
              code with your phone.
            </p>
            <a href="/transfers" className="underline text-primary text-xl">
              {" "}
              Learn More
            </a>
          </div>
        </div>
      </section>

      <section className="container mx-auto mt-14">
        <h1 className="text-4xl font-bold">Faq</h1>
        <FaqComponent />
        <div className="mt-8">
          <a href="/faq" className="underline text-primary text-xl ">
            View all
          </a>
        </div>
      </section>
      <section className="p-4">
        <Newsletter />
      </section>

      <Footer />
    </div>
  );
};
