import React from "react";
import Footer from "../../components/Footer";
import Newsletter from "../../components/Newsletter";
import Navbar from "../../components/Navbar";
import FaqComponent from "../../components/Faq";

const Faq = () => {
  return (
    <div>
      <Navbar initialTextColor="text-secondary"/>
      <div className="bg-[#F7F7F7] h-[80px]"></div>
      <div className="bg-[#F7F7F7] h-40">
        <h1 className="text-4xl text-center font-bold pt-12">
          Frequently Asked Questions
        </h1>
      </div>

      <div className="container mx-auto">
        <FaqComponent />
      </div>
      <div className="px-4">

      <Newsletter />
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
