import Navbar from "../../components/Navbar";
import HoldingHands from "../../assets/img/holdingHands.png";
import Mission from "../../assets/img/mission.png";
import Vision from "../../assets/img/vision.png";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";

const Aboutus = () => {
  return (
    <div>
      <Navbar initialTextColor="text-secondary"/>
      <div className="bg-white h-[80px] hidden md:block"></div>
      <div className="p-4 md:container mx-auto mt-8 md:mt-20 flex flex-col md:flex-row text-secondary">
        <div className="md:w-1/2">
          <h1 className="text-2xl md:text-5xl font-bold">About NovoPay</h1>
          <p className="mt-4 md:mt-14 text-xl max-w-[500px]">
            nt occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id esnt occaecat cupidatat non proident, nt
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id esnt occaecat cupidatat non proident, nt occaecat
            cupidatat no lpa qui officia deserunt mollit anim id esnt occaecat
            cupidatat non proident, nt occaecat cupidatat no
          </p>
        </div>
        <div>
          <img src={HoldingHands} alt="team Holding Hands" className="w-full mt-6" />
        </div>
      </div>

      <div className="p-4 md:container mx-auto text-secondary mt-24 flex flex-col md:flex-row gap-8">
        <div>
          <h1 className="text-4xl font-bold mb-6">Our Mission</h1>
          <div className="bg-[#EDEDF4] p-4 md:p-8 rounded-3xl">
            <img src={Mission} alt="team Holding Hands" className="" />
            <p className="mt-14 text-xl max-w-[500px]">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupidatat non proident, nt
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupidatat non proident.
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-4xl font-bold mb-6">Our Vision</h1>
          <div className="bg-[#EDEDF4] p-4 md:p-8 rounded-3xl">
            <img src={Vision} alt="team Holding Hands" className="" />
            <p className="mt-14 text-xl max-w-[500px]">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupidatat non proident, nt
              occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupidatat non proident.
            </p>
          </div>
        </div>
      </div>

      <div className="p-4">
      <Newsletter />
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
