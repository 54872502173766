import { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { toast } from "react-toastify";

const Waitlist = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");

  const submit = async (e) => {
    e.preventDefault();

    const data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "fullname",
          value: fullname,
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow NovoBank Africa to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from NovoBank Africa.",
            },
          ],
        },
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/46635353/e36ccf88-ae3e-49d0-aece-328ac25e68a7`,
      data
    );
    if (response.status === 200) {
      toast.success("Form submitted. Thank you for joining our waitlist");
    } else {
      toast.error("Please fill input fields with (*)");
    }

    setFullname("");
    setEmail("");

    // window.location.reload();
  };

  return (
    <div>
      <Navbar initialTextColor="text-white" />
      <div className="waitlist-img w-screen h-[546px] md:h-[800px] lg:h-screen bg-contain bg-no-repeat md:bg-cover flex flex-col justify-center">
        <div className="container mx-auto">
          <div className="bg-white rounded-3xl shadow-2xl md:w-[500px] p-4 md:p-10">
            <h1 className="font-bold text-4xl">Join Waitlist</h1>
            <p className="mt-2">
              Join our waitlist to be the first to try Novo
            </p>
            <form className="mt-20 flex flex-col  w-full">
              <div>
                <label for="name">Full name</label>
                <input
                  type="text"
                  placeholder="Name"
                  className="border border-gray rounded-lg ps-3 w-full h-12 mt-2"
                  onChange={(e) => setFullname(e.target.value)}
                  required
                />
              </div>
              <div className="mt-6">
                <label for="email">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-gray rounded-lg ps-3 w-full h-12 mt-2"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {email && fullname ? (
                <button
                  onClick={submit}
                  className="bg-[#000000] text-white py-4 w-full rounded-full mt-6 hover:shadow-xl duration-300"
                >
                  SUBMIT
                </button>
              ) : (
                <button
                  disabled
                  onClick={submit}
                  className="bg-[#0000007b] text-white py-4 w-full rounded-full mt-6 hover:shadow-xl duration-300"
                >
                  SUBMIT
                </button>
              )}

            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Waitlist;
