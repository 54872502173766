import Aboutus from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Faq from "./pages/faq/faq";
import { Cards } from "./pages/features/cards";
import { Transfers } from "./pages/features/transfer";
import { Home } from "./pages/home/Home";
import Waitlist from "./pages/waitlist/waitlist";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Routes, Route } = require("react-router-dom");

function App() {
  return (
    <>
     <ToastContainer />
     <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/transfers" element={<Transfers />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/waitlist" element={<Waitlist />} />
        </Routes>
    </>
  );
}

export default App;
