import Navbar from "../../components/Navbar";
import Homepage from "../../assets/img/homepage.png";
import ScanPage from "../../assets/img/scanPage.png";
import RequestCash from "../../assets/img/requestCash.png";
import AppScreenshot from "../../assets/img/appScreenshot.png";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";

export const Transfers = () => {
  return (
    <div>
      <Navbar initialTextColor="text-secondary" />
      <div className="bg-transparent h-[80px]"></div>
      <div className="p-4 md:container mx-auto lg:h-screen flex flex-col md:flex-row justify-between items-center">
        <div className="p-2 md:w-2/3 text-secondary">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-6xl lg:w-[700px]">
            Make fast & easy money transfers on Novo.
          </h1>
          <p className="my-4 md:mt-8 text-sm lg:w-[600px] md:text-base">
            nt occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id esnt occaecat cupida
          </p>
          <div className="mt-6  hover:scale-[1.02] duration-300">
            <a
              href="/waitlist"
              className="bg-primary px-2 py-2 md:h-12 md:py-3  md:px-8 rounded-md text-white"
            >
              Join Waitlist
            </a>
          </div>
        </div>
        <div className="w-4/5 md:w-2/5 lg:w-full mt-8">
          <img src={Homepage} alt="Novo app screenshot" />
        </div>
      </div>

      <section className="md:container p-4 py-12 text-secondary mx-auto">
        {/* Replace icons svg*/}
        <h1 className="capitalize text-2xl md:text-4xl font-semibold">
          novo transfer
        </h1>
        <div className="mt-8 md:mt-20 md:flex overflow-x-scroll gap-4">
          <div className="flex justify-between gap-2 md:gap-4 mt-2">
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-2 md:gap-4 mt-2">
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
            <div className="w-full h-full md:w-72 border border-gray rounded-2xl p-3 md:px-4 md:py-6">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7360_6412)">
                  <path
                    d="M26.666 5.33331H5.33268C3.85268 5.33331 2.67935 6.51998 2.67935 7.99998L2.66602 24C2.66602 25.48 3.85268 26.6666 5.33268 26.6666H26.666C28.146 26.6666 29.3327 25.48 29.3327 24V7.99998C29.3327 6.51998 28.146 5.33331 26.666 5.33331ZM25.3327 24H6.66602C5.93268 24 5.33268 23.4 5.33268 22.6666V16H26.666V22.6666C26.666 23.4 26.066 24 25.3327 24ZM26.666 10.6666H5.33268V7.99998H26.666V10.6666Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7360_6412">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="md:text-xl font-semibold">Instant Transfers</h4>
              <p className="mt-4 md:mt-8 text-sm md:text-base">
                Use Novo virtual card for your local and international
                transactions with no hassle.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="p-4 md:container text-secondary mx-auto flex flex-col-reverse justify-between items-center md:flex-row">
          <div className="bg-[#FFF9E6] px-14 pt-16 rounded-3xl md:w-1/2 lg:w-2/5">
            <img
              src={AppScreenshot}
              alt="Guy showing Novo App on Phone"
              className="w-full"
            />
          </div>

          <div className="md:p-4 md:w-1/2">
            <h1 className="text-2xl md:text-3xl lg:text-5xl lg:w-[500px] font-bold">
              Transfer to Novo accounts & other banks.
            </h1>
            <p className="my-8 font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupida
            </p>
          </div>
        </div>
      </section>

      <section className="my-14">
        <div className="p-4 md:container text-secondary mx-auto flex flex-col justify-between items-center md:flex-row">
          <div className="md:p-4 md:w-1/2">
            <h1 className="text-2xl md:text-3xl lg:text-5xl lg:w-[500px] font-bold">
              Accept cash request from your friends & family.
            </h1>
            <p className="my-8 font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupida
            </p>
          </div>

          <div className="bg-[#EDEDF4] px-2 pt-8 rounded-3xl md:w-1/2 lg:w-2/5">
            <img
              src={RequestCash}
              alt="Guy showing Novo App on Phone"
              className="w-full"
            />
          </div>
        </div>
      </section>

      <section>
        <div className="p-4 md:container text-secondary mx-auto flex flex-col-reverse justify-between items-center md:flex-row">
          <div className="bg-[#FFF9E6] px-24 pt-16 rounded-3xl md:w-1/2 lg:w-2/5">
            <img
              src={ScanPage}
              alt="Guy showing Novo App on Phone"
              className="w-full"
            />
          </div>

          <div className="md:p-4 md:w-1/2">
            <h1 className="text-2xl md:text-3xl lg:text-5xl lg:w-[500px] font-bold">
              Use QR Code scanner to make and receive payments.
            </h1>
            <p className="my-8 font-normal">
              nt occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id esnt occaecat cupida
            </p>
          </div>
        </div>
      </section>

      <section className="p-4">
        <Newsletter />
      </section>

      <Footer />
    </div>
  );
};
